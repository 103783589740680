// const handle = "pompeopaula";

const links = [
    {
        label: "Github",
        url: 'https://github.com/paulapompeo'
    },
    {
        label: "Instagram",
        url: 'https://www.instagram.com/paulapompeo_'
    },
    {
        label: "Twitter",
        url: 'https://twitter.com/pompeopaula'
    },
    {
        label: "Youtube",
        url: 'https://www.youtube.com/channel/UCMKkxjjoPDY8KtoyZomA5qQ?view_as=subscriber'
    },
    {
        label: "Unsplash",
        url: 'https://github.com/paulapompeo'
    },
]

export default links;