import { Github } from '@styled-icons/boxicons-logos/Github';
import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { Youtube } from '@styled-icons/boxicons-logos/Youtube';
import { Unsplash } from '@styled-icons/boxicons-logos/Unsplash';

const Icons = {
    Github,
    Twitter,
    Youtube, 
    Unsplash, 
    Instagram,
}

export default Icons;
